import React from "react";
import styled from "styled-components";

const Footer = () => (
  <Container>
    <Bar>© {new Date().getFullYear()}</Bar>
    <p>| P.IVA IT15065541003</p>
  </Container>
);

export default Footer;

const Container = styled.div`
  margin-top: 8vh;
  display: flex;
  justify-content: center;
  background-color: #ffffff;
`;

const Bar = styled.footer`
  height: 2rem;
  margin-right: 0.4em;
`;
