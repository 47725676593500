import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const ListLink = (props) => (
  <li
    style={{
      display: `inline-block`,
      marginRight: `.8rem`,
      textDecoration: `none`,
    }}
  >
    <LinkWrapper to={props.to}>{props.children}</LinkWrapper>
  </li>
);

const Header = ({ siteTitle }) => (
  <HeaderWrapper>
    <UnList>
      <ListLink to="/">HOME</ListLink>
      <ListLink to="/servizi/">SERVIZI</ListLink>
      <ListLink to="/gallery/">GALLERIA</ListLink>
      <ListLink to="/contatti/">CONTATTI</ListLink>
    </UnList>
  </HeaderWrapper>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;

const HeaderWrapper = styled.header`
  background-color: rgb(249, 204, 189);
  padding: 0 6vw;
  margin: 0 !important;
  @media (max-width: 768px) {
    padding: 0;
  }
`;

const UnList = styled.ul`
  float: right;
  display: flex;
  list-style: none;
  margin: 0 !important;
  @media (max-width: 768px) {
    padding: 0;
    justify-content: center;
    padding-top: 2vh;
  }
`;

const LinkWrapper = styled(Link)`
  color: #03363d;
  text-decoration: none;
  display: inline-block;
  font-weight: 700;
  margin-top: 1rem;
  @media (max-width: 768px) {
    margin: 0rem;
  }
`;
