import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";

import Header from "./Header";
import Footer from "./Footer";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <BodyWrapper>
      <Header siteTitle={data.site.siteMetadata.title} />
      <MainWrapper>{children}</MainWrapper>
      <Footer />
    </BodyWrapper>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

const BodyWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  overflow-y: hidden;
`;

const MainWrapper = styled.main`
  flex: 1;
  padding: 0 16vw;
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    padding: 0 10vw;
  }
  @media (max-width: 1024px) {
    padding: 0 6vw;
  }
  @media (max-width: 768px) {
    padding: 0 2vw;
  }
`;
